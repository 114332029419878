/**
 * Defer execution till later.
 *
 */
export class Deferred {
    constructor() {
        /**
         * awaitable promise
         */
        this.promise = new Promise((resolve, reject) => {
            this.reject = reject;
            this.resolve = resolve;
        });
    }
}
