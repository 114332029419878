import { focusFirstField } from './focus-first-field';
import { handleWatermark } from './handle-watermark';
/**
 * Legacy operations
 *
 * Operations that is done in the old consumer app that should be done in gjs-components.
 */
export class LegacyService {
    constructor(props) {
        this.props = props;
    }
    async postRender(stepId) {
        focusFirstField({ ...this.props, stepId });
        return handleWatermark({ ...this.props, stepId });
    }
}
