import { extraConfig } from '@upflowy/firebaseconfig';
import { initFirebaseApp } from './firebase-app';
async function setup() {
    var _a;
    const app = await initFirebaseApp();
    if (extraConfig.emulation.auth) {
        // eslint-disable-next-line no-console
        console.log('***** Using Auth Emulator *****');
        const { connectAuthEmulator, getAuth } = await import('firebase/auth');
        connectAuthEmulator(getAuth(app), (_a = extraConfig.emulation.authUrl) !== null && _a !== void 0 ? _a : 'http://localhost:9099');
    }
}
let setupPromise;
export async function doSignInAnonymously() {
    if (!setupPromise)
        setupPromise = setup();
    await setupPromise;
    const { signInAnonymously, getAuth } = await import('firebase/auth');
    return signInAnonymously(getAuth());
}
