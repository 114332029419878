export class NotificationService {
    constructor(config) {
        this.config = config;
    }
    post(message) {
        if (this.config.scope.origin) {
            this.config.channel.postMessage({
                ...message,
                source: this.config.source,
                containerId: this.config.scope.containerId,
            }, this.config.scope.origin);
        }
    }
}
