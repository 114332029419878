import { getActions } from '../../actions';
import { indexedGuard } from '../../guards/indexed-guard';
import { asyncRegister } from '../async-register';
import { LegacyService } from '../legacy-service';
import { stateMachineService } from '../state-machine-service';
import { UrlResourceLoader } from '../url-resource-loader';
export function createInterpreter({ flowConfig, config, flowVersionId, flowId, resourceLoader, parentNotificationService, sendEventService, userPromise, customCSSService, watermarkService, domService, apiService, }) {
    var _a;
    const userIdPromise = userPromise.then((user) => user.uid);
    const sendParent = (message) => parentNotificationService.post(message);
    const sendEvent = (event) => sendEventService.send(event);
    const createEvent = async (event) => ({
        type: 'API_RESPONSE',
        data: await sendEventService.send(event),
    });
    const legacyService = new LegacyService({
        ...config,
        flowId,
        sendParent,
        organisationId: flowConfig.organisationId,
        userIdPromise,
        domService,
        watermarkService,
    });
    const actionHandlers = getActions({
        ...config,
        domService,
        apiService,
        organisationId: flowConfig.organisationId,
        containerId: (_a = config.containerId) !== null && _a !== void 0 ? _a : '',
        flowId,
        flowVersionId: flowVersionId !== null && flowVersionId !== void 0 ? flowVersionId : flowConfig.flowVersionId,
        resourceLoader: flowConfig.relativeBasePath
            ? new UrlResourceLoader({
                baseResourceUrl: `${config.baseResourceUrl}${flowConfig.relativeBasePath}`,
            })
            : resourceLoader,
        sendParent,
        sendEvent,
        createEvent,
        asyncRegister: asyncRegister(),
        userPromise,
        userIdPromise,
        customCSSService,
        watermarkService,
        legacyService,
    });
    return stateMachineService({
        machineConfig: flowConfig.machine,
        ...config,
        context: config.initialContext,
        domService,
        guards: {
            indexedGuard,
        },
        actionHandlers,
    });
}
