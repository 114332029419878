import { assign } from 'xstate';
const extractPercentage = (allocation) => { var _a, _b; return Number((_b = (_a = allocation.match(/([\d.]+)%/)) === null || _a === void 0 ? void 0 : _a[1]) !== null && _b !== void 0 ? _b : '0'); };
export const resolveAbTestAssignment = ({ abtests }, action) => {
    var _a;
    if (abtests === null || abtests === void 0 ? void 0 : abtests[action.name])
        return abtests;
    const rand = Math.random() * 100;
    let sum = 0;
    const selectedVariation = (_a = action.variations.find(({ allocation }) => {
        if (rand >= sum && rand < extractPercentage(allocation) + sum)
            return true;
        sum += extractPercentage(allocation);
        return false;
    })) !== null && _a !== void 0 ? _a : action.variations[action.variations.length - 1];
    if (selectedVariation.name) {
        return {
            ...abtests,
            [action.name]: selectedVariation.name,
        };
    }
    return abtests;
};
export const startAbTestAction = assign((context, _event, meta) => ({
    abtests: resolveAbTestAssignment(context, meta.action),
}));
