import { mapEvent } from './send-event-actions';
import { EVENT_TYPE_FLOW_COMPLETE, EVENT_TYPE_REDIRECT } from '../event-types';
import { renderTemplate } from '../services/mustache-renderer';
export const addHttps = (url) => {
    const pattern = /^(https?|ftp):\/\//;
    if (!pattern.test(url)) {
        return `https://${url}`;
    }
    return url;
};
export function endAction({ sendParent, flowId, isStandaloneApp, createEvent, }) {
    return (context, _event, { action: { action, redirectUrl }, }) => {
        const eventPromise = createEvent(mapEvent({
            context,
            type: EVENT_TYPE_FLOW_COMPLETE,
            state: 'end',
        }));
        if (isStandaloneApp) {
            eventPromise.then(() => {
                if (redirectUrl) {
                    createEvent(mapEvent({
                        context,
                        type: EVENT_TYPE_REDIRECT,
                        state: 'redirect',
                    })).then(() => {
                        renderTemplate(redirectUrl, context, false).then((url) => {
                            sendParent({
                                type: EVENT_TYPE_REDIRECT,
                                redirectUrl: addHttps(url),
                                data: {
                                    redirectUrl: addHttps(url),
                                },
                                flowId,
                                state: 'end',
                            });
                            window.location.href = addHttps(url);
                        });
                    });
                }
                else {
                    window.location.reload();
                }
            });
        }
        else if (action === 'redirect' && redirectUrl) {
            eventPromise.then(() => {
                createEvent(mapEvent({
                    context,
                    type: EVENT_TYPE_REDIRECT,
                    state: 'redirect',
                })).then(() => {
                    renderTemplate(redirectUrl, context, false).then((url) => {
                        sendParent({
                            type: EVENT_TYPE_REDIRECT,
                            redirectUrl: addHttps(url),
                            data: {
                                redirectUrl: addHttps(url),
                            },
                            flowId,
                            state: 'end',
                        });
                    });
                });
            });
        }
        else {
            eventPromise.then(() => {
                sendParent({
                    type: EVENT_TYPE_FLOW_COMPLETE,
                    flowId,
                    state: 'end',
                });
                window.location.reload();
            });
        }
    };
}
