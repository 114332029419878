const WATERMARK_CONTAINER_CSS = '.UP_waterMark';
const WATERMARK_LINK_CSS = '.UP_waterMark a';
export async function handleWatermark({ organisationId, userIdPromise, stepId, flowId, isStandaloneApp, sendParent, domService, watermarkService, }) {
    var _a, _b;
    const uid = await userIdPromise;
    if (!domService.isStepActive(stepId))
        return;
    const hasWatermark = await watermarkService.hasWatermarkPromise;
    if (domService.hasUiFlag('has-watermark') || hasWatermark) {
        const showWaterMarkLink = (_a = domService
            .getStepElement()) === null || _a === void 0 ? void 0 : _a.querySelector(WATERMARK_LINK_CSS);
        if (showWaterMarkLink) {
            const link = 'https://www.upflowy.com/?utm_source=flow&utm_medium=referral' +
                `&utm_campaign=${organisationId}&utm_id=${uid}&utm_term=${stepId}&utm_content=${flowId}`;
            showWaterMarkLink.setAttribute('href', link);
            showWaterMarkLink.setAttribute('target', '_blank');
            if (!isStandaloneApp) {
                showWaterMarkLink.addEventListener('click', (event) => {
                    sendParent({
                        type: 'REDIRECT',
                        redirectUrl: link,
                        state: stepId,
                        flowId,
                    });
                    event.preventDefault();
                });
            }
        }
    }
    else {
        const showWaterMarkContainer = (_b = domService
            .getStepElement()) === null || _b === void 0 ? void 0 : _b.querySelector(WATERMARK_CONTAINER_CSS);
        if (showWaterMarkContainer)
            showWaterMarkContainer.style.visibility = 'hidden';
    }
}
