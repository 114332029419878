import { logger } from '@upflowy/logger';
import { ORIGIN_STATE_ID, } from '@upflowy/types';
import { assign, spawn } from 'xstate';
import { dataParseFlattenedJSON } from './data-parse-flattened-json';
import { mapEvent } from './send-event-actions';
import { EVENT_TYPE_SUBMIT } from '../event-types';
import { extractActionMeta } from '../helpers/xstate';
export const reEnableStepCss = (domService) => {
    const stepElement = domService.getStepElement();
    if (stepElement)
        stepElement.removeAttribute('style');
};
export function createSubmitAction({ flowId, createEvent, sendParent, domService, ...rest }) {
    return assign((context, event, meta) => ({
        busyFlags: { submitEvent: true },
        ref: spawn((callback) => {
            var _a;
            const { waitForApiResponse } = extractActionMeta(meta);
            const stepId = (_a = context.stepId) !== null && _a !== void 0 ? _a : ORIGIN_STATE_ID;
            const promise = createEvent(mapEvent({
                event,
                context,
                state: context.stepId,
                type: event.type,
                target: 'step',
                ...rest,
            }));
            if (waitForApiResponse) {
                promise
                    .then((result) => {
                    var _a, _b, _c, _d;
                    const eventResult = result;
                    const webHookErrorMessage = ((_a = eventResult.data.trace) === null || _a === void 0 ? void 0 : _a.errors.message) ||
                        'There was an issue proceeding to the next step.';
                    if (!eventResult.data.success) {
                        // We want to allow the user to modify his inputs if a webhook fails.
                        reEnableStepCss(domService);
                        callback({
                            type: 'FAILURE',
                            message: `${webHookErrorMessage}`,
                            error: (_b = eventResult.data.error) !== null && _b !== void 0 ? _b : undefined,
                            errorType: 'api',
                        });
                        return;
                    }
                    const JSONData = JSON.parse(((_c = eventResult.data.trace) === null || _c === void 0 ? void 0 : _c.response) || '""');
                    JSONData.data.flattenedJSON = JSON.stringify(dataParseFlattenedJSON(JSONData.data.flattenedJSON));
                    if (!JSONData.data.softFlattened) {
                        JSONData.data.flattenedJSON = '""';
                        JSONData.data.softFlattened = '""';
                    }
                    callback({
                        type: 'API_RESPONSE',
                        data: {
                            ...event.data,
                            success: eventResult.data.success,
                            message: eventResult.data.message,
                            error: eventResult.data.error,
                            responeStatus: (_d = eventResult.data.trace) === null || _d === void 0 ? void 0 : _d.responseStatus,
                            data: JSON.parse(JSONData.data.softFlattened),
                            ...JSON.parse(JSONData.data.flattenedJSON),
                        },
                    });
                    sendParent({
                        state: stepId,
                        type: EVENT_TYPE_SUBMIT,
                        flowId,
                    });
                })
                    .catch((error) => {
                    // We want to allow the user to modify his inputs if a webhook fails.
                    reEnableStepCss(domService);
                    callback({
                        type: 'FAILURE',
                        message: 'Unable to submit data',
                        error,
                        errorType: 'api',
                    });
                    // Don't do anything special with error
                    logger.logException(error, { message: 'While invoking webhook callback' });
                });
            }
            else {
                callback({
                    ...event,
                    type: 'API_RESPONSE',
                });
                sendParent({
                    state: stepId,
                    type: EVENT_TYPE_SUBMIT,
                    flowId,
                });
                promise.catch((error) => {
                    // Don't do anything special with error
                    logger.logException(error, {
                        message: 'Error while invoking service (not waiting for response)',
                    });
                });
            }
        }),
    }));
}
