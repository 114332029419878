export class UrlResourceLoader {
    constructor({ baseResourceUrl }) {
        this.toResourceUrl = (path) => `${this.prefix}${encodeURIComponent(path)}?alt=media`;
        this.loadJson = async (path) => {
            const result = await fetch(this.toResourceUrl(path));
            if (result.status === 404)
                return null;
            return (await result.json());
        };
        this.load = async (path) => {
            const result = await fetch(this.toResourceUrl(path));
            return result.text();
        };
        this.prefix = baseResourceUrl;
    }
    static create({ baseResourceUrl, flowId, flowVersionId, }) {
        if (!flowId && !flowVersionId)
            return new UrlResourceLoader({ baseResourceUrl });
        const subPath = flowVersionId ? `flows/${flowId}/versions/${flowVersionId}` : `flows/${flowId}`;
        /** flowVersionId available in case of preview, and to fetch preview flow we need to call firebase function */
        return new UrlResourceLoader({
            baseResourceUrl: `${baseResourceUrl}${flowVersionId ? '/' : '%2F'}${encodeURIComponent(subPath)}`,
        });
    }
}
