import { WATERMARK_CONTAINER_CSS } from '../constants';
import { EVENT_TYPE_REDIRECT, EVENT_TYPE_SSO_INIT, EVENT_TYPE_SUBMIT, isConsumerEventType, } from '../event-types';
const SECURE_TYPES = ['password'];
const FORM_INPUTS_SELECTOR = 'input:not([type=submit]),select,textarea';
export const formValuesToJSON = (formEl) => {
    const nodes = formEl.querySelectorAll(FORM_INPUTS_SELECTOR);
    return [...nodes].reduce((memo, node) => {
        const key = `user.${node.getAttribute('name')}`;
        const existingValueForKey = memo[key];
        let value;
        const type = node.getAttribute('type');
        if (type === 'checkbox') {
            // handle checkbox & radio as array;
            value = existingValueForKey || [];
            if (node.checked) {
                value = value.concat([node.value]);
            }
        }
        else if (type === 'radio') {
            value = node.checked && node.value;
        }
        else if (type !== null && SECURE_TYPES.includes(type)) {
            value = '';
        }
        else {
            value = node.value;
        }
        return !value
            ? memo
            : {
                ...memo,
                [key]: value,
            };
    }, {});
};
export function secureValues(formElement) {
    const nodes = formElement.querySelectorAll(FORM_INPUTS_SELECTOR);
    return [...nodes].reduce((memo, node) => {
        const type = node.getAttribute('type');
        let value;
        const key = `form.${node.name}`;
        if (type && SECURE_TYPES.includes(type)) {
            value = node.value;
        }
        return !value
            ? memo
            : {
                ...memo,
                [key]: value,
            };
    }, {});
}
export const handleClickEventOfNonType = (event) => {
    const target = event.target;
    const targetType = target.getAttribute('type');
    if (targetType &&
        (EVENT_TYPE_SUBMIT === targetType.toUpperCase() ||
            targetType.toUpperCase().endsWith(EVENT_TYPE_SSO_INIT))) {
        // form submit
        return targetType.toUpperCase();
    }
    // TODO check this. This should really not be necessary
    // dismiss
    // const dismisses = document.querySelectorAll(`[data-xstate-event="${EVENT_TYPE_DISMISS}"]`);
    // if ([...dismisses].some((d) => d.contains(target))) return EVENT_TYPE_DISMISS;
    return undefined;
};
export const submitForm = (form, callback) => {
    // prevent processing when form isn't valid
    if (form.classList.contains('invalid'))
        return;
    callback({
        type: EVENT_TYPE_SUBMIT,
        source: 'form',
        data: formValuesToJSON(form),
        secure: secureValues(form),
    });
};
export function bindEventListeners(callback) {
    const element = document.getElementById('step');
    element === null || element === void 0 ? void 0 : element.addEventListener('click', (mouseEvent) => {
        var _a, _b;
        if (!(mouseEvent.target instanceof HTMLElement)) {
            return false;
        }
        if (mouseEvent.target.classList.contains(WATERMARK_CONTAINER_CSS)) {
            callback({
                type: EVENT_TYPE_REDIRECT,
                destination: 'upflowy',
            });
        }
        const type = (_a = mouseEvent.target.getAttribute('data-xstate-event')) !== null && _a !== void 0 ? _a : (_b = handleClickEventOfNonType(mouseEvent)) === null || _b === void 0 ? void 0 : _b.toUpperCase();
        if (type && isConsumerEventType(type)) {
            // Prevent click on submit from bubbleling and create double form issues
            mouseEvent.preventDefault();
            if (type === EVENT_TYPE_SUBMIT) {
                // Prevent the user to click on other submit buttons of the step
                if (element)
                    element.style.pointerEvents = 'none';
                const parentForm = mouseEvent.target.closest('form');
                if (parentForm instanceof HTMLFormElement)
                    submitForm(parentForm, callback);
            }
            else {
                callback({
                    mouseEvent,
                    // TODO: fix this type issue
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    type,
                });
            }
        }
        return true;
    });
}
