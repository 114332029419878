export function bindParentEventListener(callback) {
    if (window.parent) {
        window.addEventListener('message', (message) => {
            var _a;
            const dataType = (_a = message === null || message === void 0 ? void 0 : message.data) === null || _a === void 0 ? void 0 : _a.type;
            if (!dataType)
                return;
            // The following is to filter out redux devtools
            if (dataType === 'ACTION')
                return;
            const { data, meta } = message === null || message === void 0 ? void 0 : message.data;
            const prefixedData = data
                ? Object.entries(data).reduce((p, [key, value]) => ({
                    ...p,
                    [`user.${key}`]: value,
                }), {})
                : {};
            // TODO need to figure out what to do with non ConsumerEventTypes
            callback({
                type: dataType,
                data: {
                    ...prefixedData,
                    triggerId: meta === null || meta === void 0 ? void 0 : meta.triggerId,
                },
            });
        });
    }
}
