import { v4 as uuidv4 } from 'uuid';
import { EVENT_TYPE_FLOW_TRIGGER } from '../../event-types';
import { bindEventListeners } from '../../tasks/bind-event-listeners';
import { bindParentEventListener } from '../parent-listener';
export function addListeners({ isStandaloneApp, sessionId }, interpreter) {
    bindEventListeners((event) => interpreter.send(event));
    if (isStandaloneApp) {
        interpreter.send(EVENT_TYPE_FLOW_TRIGGER, {
            data: {
                sessionId: sessionId !== null && sessionId !== void 0 ? sessionId : uuidv4(),
            },
        });
    }
    else {
        bindParentEventListener((event) => interpreter.send(event));
    }
}
