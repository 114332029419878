import { ConsumerAnalyticsService, FlowStatus } from '@upflowy/logger';
import { consumer } from '@upflowy/types';
import { isGoogleFont } from './helper';
import { envConfig } from '../../env-config';
import { getFileExtenstionFromUrl, getUrlQueryParam } from '../../helpers/app-url';
import { Deferred } from '../../helpers/deferred';
import { getNanoId } from '../get-config';
import { parseUrl } from '../get-config/parse-url';
const STEP_ELEMENT_ID = 'step';
export class DomService {
    constructor(uiSettingsElement = document.getElementById('root'), headElement = document.head, stepElement = document.getElementById(STEP_ELEMENT_ID)) {
        this.headElement = headElement;
        this.stepElement = stepElement;
        this.uiSettingToAttrName = (flag) => `${consumer.CONSUMER_UI_SETTING_PREFIX}${flag}`;
        if (!uiSettingsElement)
            throw new Error('UI Element not defined');
        this.uiSettingsElement = uiSettingsElement;
    }
    addStyle({ url, slot }) {
        let link;
        const deferred = new Deferred();
        if (slot) {
            link = this.headElement.querySelector(`link[data-slot="${slot}"]`);
            if (!link) {
                link = document.createElement('link');
                link.setAttribute('data-slot', slot);
                link.rel = 'stylesheet';
                link.type = 'text/css';
                link.media = 'all';
                link.href = url;
                this.headElement.appendChild(link);
            }
            else {
                link.href = url;
            }
        }
        else {
            link = document.createElement('link');
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.media = 'all';
            this.headElement.appendChild(link);
        }
        link.addEventListener('load', () => {
            deferred.resolve(true);
        });
        return deferred.promise;
    }
    addScript(url) {
        const scriptElement = document.createElement('script');
        scriptElement.src = url;
        const deferred = new Deferred();
        scriptElement.addEventListener('load', () => {
            deferred.resolve(true);
        });
        this.headElement.appendChild(scriptElement);
        return deferred.promise;
    }
    addFontStyle({ url, slot, type }) {
        const deferred = new Deferred();
        const link = document.createElement('link');
        link.setAttribute('data-slot', slot);
        link.rel = 'preload';
        link.as = 'font';
        link.href = url;
        link.crossOrigin = 'anonymous';
        if (type)
            link.type = type;
        this.headElement.appendChild(link);
        link.addEventListener('load', () => {
            deferred.resolve(true);
        });
        return deferred.promise;
    }
    addFont(font) {
        const { url, id } = font;
        const params = { url, slot: id };
        if (isGoogleFont(url))
            this.addStyle(params);
        else {
            const fontType = getFileExtenstionFromUrl(url);
            if (fontType)
                params.type = `font/${fontType}`;
            this.addFontStyle(params);
        }
        const styleElement = document.createElement('style');
        const fontFace = document.createTextNode(`@font-face{font-family: ${font.name}; src: url(${font.url});}`);
        styleElement.appendChild(fontFace);
        this.headElement.appendChild(styleElement);
    }
    getUiSettingsElement() {
        return this.uiSettingsElement;
    }
    addUiSetting(flag, value) {
        this.uiSettingsElement.setAttribute(this.uiSettingToAttrName(flag), value || '');
    }
    removeUiSetting(setting) {
        this.uiSettingsElement.removeAttribute(this.uiSettingToAttrName(setting));
    }
    removeUiFlag(flag) {
        this.removeUiSetting(flag);
    }
    addUiFlag(flag) {
        this.addUiSetting(flag, '');
    }
    removeUiSettings(settings) {
        settings.forEach(this.removeUiSetting);
    }
    hasUiSetting(setting) {
        return this.uiSettingsElement.hasAttribute(this.uiSettingToAttrName(setting));
    }
    hasUiFlag(flag) {
        return this.hasUiSetting(flag);
    }
    getStepElement() {
        return this.stepElement;
    }
    isStepActive(stepId) {
        var _a;
        return (_a = this.getUiSettingsElement()) === null || _a === void 0 ? void 0 : _a.classList.contains(`step-${stepId}`);
    }
    setStepActive(stepId) {
        var _a;
        const classList = (_a = this.getUiSettingsElement()) === null || _a === void 0 ? void 0 : _a.classList;
        if (!classList)
            return;
        classList.forEach((cl) => {
            if (cl.startsWith('step-'))
                classList.remove(cl);
        });
        classList.add(`step-${stepId}`);
    }
    addError(error) {
        var _a;
        const errorElement = document.createElement('div');
        errorElement.classList.add('globalErrorMessage');
        errorElement.innerText = error;
        (_a = this.getStepElement()) === null || _a === void 0 ? void 0 : _a.appendChild(errorElement);
    }
    addUiClass(className) {
        this.getUiSettingsElement().classList.add(className);
    }
    removeUiClass(className) {
        var _a;
        (_a = this.getUiSettingsElement()) === null || _a === void 0 ? void 0 : _a.classList.remove(className);
    }
    renderLoader() {
        var _a;
        const stepElement = this.getStepElement();
        if (stepElement) {
            let configResourceUrl = '/generic-loader.gif';
            const { flowId, customUrl } = parseUrl(new URL(window.location.toString()));
            if (customUrl) {
                const nanoId = getNanoId(customUrl);
                configResourceUrl = `${envConfig.storageUrl}published%2Furls%2F${nanoId}%2Floader.gif?alt=media`;
            }
            else if (flowId) {
                configResourceUrl = `${envConfig.storageUrl}published%2Fflows%2F${flowId}%2Floader.gif?alt=media`;
            }
            const loader = (_a = getUrlQueryParam('loader')) !== null && _a !== void 0 ? _a : configResourceUrl;
            stepElement.innerHTML = '';
            const loaderWrapper = document.createElement('div');
            loaderWrapper.classList.add('fullpage');
            const loaderOuterObject = document.createElement('object');
            loaderOuterObject.data = loader;
            const image = document.createElement('img');
            image.classList.add('loader');
            image.src = '/generic-loader.gif';
            image.alt = 'loading';
            image.width = 100;
            image.height = 100;
            loaderOuterObject.appendChild(image);
            loaderWrapper.appendChild(loaderOuterObject);
            stepElement.appendChild(loaderWrapper);
        }
    }
    removeLoader() {
        const stepElement = this.getStepElement();
        if (stepElement) {
            const loaders = document.querySelectorAll('div.fullpage');
            loaders.forEach((loader) => stepElement.removeChild(loader));
        }
    }
    static redirectTo(url) {
        window.location.href = url;
    }
    static pageNotFound() {
        var _a;
        const resourceNotAvailableURL = (_a = getUrlQueryParam('404')) !== null && _a !== void 0 ? _a : '/404.html';
        ConsumerAnalyticsService.endTransaction(FlowStatus.NOT_FOUND);
        this.redirectTo(resourceNotAvailableURL);
    }
    static resourceNotAvailable() {
        var _a;
        const resourceNotAvailableURL = (_a = getUrlQueryParam('409')) !== null && _a !== void 0 ? _a : '/409.html';
        ConsumerAnalyticsService.endTransaction(FlowStatus.UNAVAILABLE);
        this.redirectTo(resourceNotAvailableURL);
    }
}
