export function focusFirstField({ stepId, domService, }) {
    // Focus field if first, and if "focus-elligible"
    const eligibleParentToFocus = '.UP_formContainer > .form-focus-eligible:not(.form-input-element + .form-input-element)';
    setTimeout(() => {
        const stepElement = domService.getStepElement();
        if (domService.isStepActive(stepId)) {
            const chosenInputToFocus = stepElement === null || stepElement === void 0 ? void 0 : stepElement.querySelector(`
        ${eligibleParentToFocus} input,
        ${eligibleParentToFocus} textarea
      `);
            chosenInputToFocus === null || chosenInputToFocus === void 0 ? void 0 : chosenInputToFocus.focus();
        }
    }, 10);
}
