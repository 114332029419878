import { logger } from '@upflowy/logger';
import { ORIGIN_STATE_ID, } from '@upflowy/types';
import { actions, assign } from 'xstate';
import { clearContextAction } from './clear-context-action';
import { endAction } from './end-action';
import { notifyAction } from './notify-action';
import { prepareConditionalAction } from './prepare-conditional-action';
import { readyAction } from './ready-action';
import { renderVersionAction } from './render-version-action';
import { sendEventAction } from './send-event-actions';
import { startAbTestAction } from './start-ab-test-action';
import { createSubmitAction } from './submit-action';
import { EVENT_TYPE_DISMISS, EVENT_TYPE_FLOW_READY, EVENT_TYPE_REDIRECT } from '../event-types';
export function getActions({ resourceLoader, initialContext, initialState, sendEvent, sendParent, createEvent, flowId, flowVersionId, asyncRegister, watermarkService, customCSSService, isStandaloneApp, containerId, organisationId, userPromise, userIdPromise, legacyService, domService, apiService, }) {
    const clearContext = clearContextAction({ initialContext });
    return {
        clearBusy: assign((_context, _event) => ({
            busyFlags: {},
        })),
        conditionalHandler: prepareConditionalAction,
        dismiss: actions.pure(() => [
            {
                type: 'dismiss.send',
                exec: sendEventAction({
                    type: EVENT_TYPE_DISMISS,
                    sendEvent,
                }),
            },
            {
                type: 'dismiss.sendParent',
                exec: ({ stepId }) => {
                    sendParent({
                        type: EVENT_TYPE_DISMISS,
                        state: stepId || '',
                        flowId,
                    });
                },
            },
        ]),
        end: endAction({
            flowId,
            isStandaloneApp,
            sendParent,
            createEvent,
        }),
        assignStep: assign((_ctx, _event, meta) => ({
            busyFlags: {},
            stepId: meta.action.stepId,
            stepEnteredDate: new Date().toISOString(),
        })),
        notify: notifyAction({
            flowId,
            isStandaloneApp,
            sendEvent,
            sendParent,
        }),
        ready: readyAction({
            flowId,
            containerId,
            domService,
            asyncRegister,
            resourceLoader,
            legacyService,
            sendEvent,
            sendParent,
        }),
        renderStep: (context, _event, { action: { hasNavigateBack }, }) => {
            var _a;
            const { stepId } = context;
            if (!stepId) {
                logger.warn('StepId not set');
                return;
            }
            (_a = context.stepRendererRef) === null || _a === void 0 ? void 0 : _a.send({
                type: 'transition',
                data: { parentContext: context, hasNavigateBack: !!hasNavigateBack, stepId },
            });
        },
        renderVersion: () => renderVersionAction({
            flowId,
            flowVersionId,
            apiService,
            resourceLoader,
            asyncRegister,
            watermarkService,
            customCSSService,
            isStandaloneApp,
            domService,
            userPromise,
        }),
        assignEventData: assign((ctx, event) => {
            var _a;
            return ({
                ...(typeof ctx === 'object' && ctx),
                user: {
                    ...ctx.user,
                    form: {
                        ...(_a = ctx === null || ctx === void 0 ? void 0 : ctx.user) === null || _a === void 0 ? void 0 : _a.form,
                        ...('data' in event && event.data),
                    },
                },
            });
        }),
        clearContext,
        startAbTest: startAbTestAction,
        submit: createSubmitAction({ flowId, sendParent, createEvent, domService }),
        flowReady: async (context) => {
            try {
                const result = await createEvent({
                    idToken: await userIdPromise,
                    simulated: false,
                    origin,
                    context,
                    target: 'flow',
                    state: initialState !== null && initialState !== void 0 ? initialState : ORIGIN_STATE_ID,
                    type: EVENT_TYPE_FLOW_READY,
                });
                if (!result.data.success) {
                    logger.warn('Error while sending event', {
                        errorMessage: result.data.error,
                    });
                }
            }
            catch (error) {
                logger.logException(error);
            }
        },
        redirect: (context, event) => {
            if (event.type === EVENT_TYPE_REDIRECT && event.destination === 'upflowy') {
                userIdPromise.then((userId) => sendParent({
                    type: event.type,
                    state: context.stepId || '',
                    flowId,
                    redirectUrl: 'https://www.upflowy.com/?utm_source=flow&utm_medium=referral' +
                        `&utm_campaign=${organisationId}&utm_id=${userId}&utm_term=${context.stepId}&utm_content=${flowId}`,
                }));
            }
        },
        failure: assign({
            error: (_, event) => {
                var _a, _b, _c;
                if (event.type === 'FAILURE') {
                    return (_c = (_a = event.message) !== null && _a !== void 0 ? _a : (_b = event.error) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : 'Unknown error';
                }
                logger.error('Unexpected event received', { event });
                return 'Unexpected error';
            },
        }),
    };
}
