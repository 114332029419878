const isEmpty = (arg) => !arg;
const textContains = (arg, text) => !!arg && !!text && arg.toLowerCase().indexOf(text.toLowerCase()) >= 0;
const isBetween = (value, n1, n2) => value >= n1 && value < n2;
const containsDomain = (email, domain) => email.split('@')[1].toLowerCase() === domain.toLowerCase();
const not = (fn) => (...args) => !fn(...args);
const contains = (arg, value) => !!arg && !!value && Array.isArray(value) && value.every((v) => arg.includes(v));
const notContains = (arg, value) => !!arg && !!value && Array.isArray(value) && !value.some((v) => arg.includes(v));
const compareIgnoreCase = (arg, text) => !!arg && !!text && arg.toLowerCase() === text.toLowerCase();
export const booleanExpressions = {
    isEmpty,
    equalsIgnoreCase: compareIgnoreCase,
    isNotEmpty: not(isEmpty),
    textDoesNotContain: not(textContains),
    textContains,
    textStartsWith: (arg, text) => !!arg && !!text && arg.toLowerCase().startsWith(text.toLowerCase()),
    textEndsWith: (arg, text) => !!arg && !!text && arg.toLowerCase().endsWith(text.toLowerCase()),
    isBetween,
    isNotBetween: not(isBetween),
    containsDomain,
    doesNotContainDomain: not(containsDomain),
    contains,
    notContains,
    compareIgnoreCase,
};
