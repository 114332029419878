import { AnalyticsSpanOp, AnalyticsTransactionOp, ConsumerAnalyticsService, initLogger, } from '@upflowy/logger';
import BrowserLoggerEngine from '@upflowy/logger/lib/src/engines/browser-logger-engine';
import { envConfig } from './env-config';
import { render } from './services/render-service';
/**
 * Start application
 *
 * @param fallbackHandler Handler when flow config not found. I.e. v1 flow.
 */
export function start(fallbackHandler) {
    initLogger({
        engine: BrowserLoggerEngine({
            dsn: envConfig.sentryDNS,
            release: envConfig.release,
            environment: envConfig.env,
            consoleLog: envConfig.consoleLog,
        }),
    });
    ConsumerAnalyticsService.startTransaction({
        transactionOp: AnalyticsTransactionOp.PAGELOAD,
        transactionDescription: 'Load the flow',
        spanOp: AnalyticsSpanOp.APP_LOADING,
        spanDescription: 'Load the app and choose consumer app',
    });
    render(fallbackHandler).then();
}
// Enables starting consumer v2 directly instead of being used as library
if (process.env.REACT_APP_START_CONSUMER_V2) {
    start();
}
