import { Deferred } from '../helpers/deferred';
export class WatermarkService {
    constructor(domService) {
        this.domService = domService;
        this.deferred = new Deferred();
        this.hasWatermarkPromise = this.deferred.promise;
    }
    async handle(showWatermark) {
        if (showWatermark)
            this.domService.addUiFlag('has-watermark');
        else
            this.domService.removeUiFlag('has-watermark');
        this.deferred.resolve(true);
    }
}
