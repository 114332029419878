import { ORIGIN_STATE_ID } from '@upflowy/types';
import { prefixRecord } from '../helpers/utils';
export const mapEvent = ({ event, context, target = 'flow', type, state, triggerId, }) => {
    var _a, _b, _c, _d;
    return ({
        target,
        type: type,
        state: state !== null && state !== void 0 ? state : ORIGIN_STATE_ID,
        stepId: context.stepId,
        origin,
        triggerId: triggerId !== null && triggerId !== void 0 ? triggerId : context.triggerId,
        context: {
            ...((_b = (_a = context.user) === null || _a === void 0 ? void 0 : _a.form) !== null && _b !== void 0 ? _b : {}),
            ...prefixRecord((_d = (_c = context.user) === null || _c === void 0 ? void 0 : _c.meta) !== null && _d !== void 0 ? _d : {}, 'meta.'),
            ...(event && 'data' in event && event.data),
            sessionId: context.sessionId,
            stateEnteredDate: context.stepEnteredDate,
            abtests: context.abtests,
            triggerId: triggerId !== null && triggerId !== void 0 ? triggerId : context.triggerId,
        },
    });
};
export function sendEventAction({ sendEvent, ...rest }) {
    return (context, event) => {
        sendEvent(mapEvent({ context, state: context.stepId, event, ...rest }));
    };
}
