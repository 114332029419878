import UAParser from 'ua-parser-js';
export function getMetaData({ location }) {
    var _a;
    const navData = UAParser(navigator.userAgent);
    const meta = {
        'browser.name': navData.browser.name,
        'browser.version': navData.browser.version,
        'operatingSystem.version': navData.os.version,
        'operatingSystem.name': navData.os.name,
        'device.type': (_a = navData.device.type) !== null && _a !== void 0 ? _a : 'desktop',
        'location.city': location === null || location === void 0 ? void 0 : location.city,
        'location.region': location === null || location === void 0 ? void 0 : location.region,
        'location.country': location === null || location === void 0 ? void 0 : location.country,
    };
    return meta;
}
