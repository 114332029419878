import { start } from '@upflowy/consumer-v2';
import {
  AnalyticsSpanOp,
  AnalyticsTransactionOp,
  ConsumerAnalyticsService,
  initLogger,
  logger,
  ReactLoggerEngine,
} from '@upflowy/logger';
import { helper } from '@upflowy/types';

import config from './helpers/config';

const url = new URL(window.location.toString());
const version = url.searchParams.get('u-version');

const startV1 = () => {
  import('./v1').then();
};

const semVer = version ? helper.parseSemver(version) : undefined;

/*
If we can determine from the URL that it is version 1 execute version immediately.
Otherwise, the consumer v2 start will determine what app version it is and load
v2 or revert to v1 depending on version settings.
 */
if (semVer && semVer[0] < 2) {
  initLogger({
    engine: ReactLoggerEngine({
      dsn: config.sentryDNS,
      release: process.env.REACT_APP_VERSION || 'UNKNOWN',
      environment: config.env,
      consoleLog: !!process.env.REACT_APP_SENTRY_CONSOLE_LOG,
    }),
  });
  logger.setTag('AppVersion', '1.0.0');
  ConsumerAnalyticsService.startTransaction({
    transactionOp: AnalyticsTransactionOp.PAGELOAD,
    transactionDescription: 'Load the flow',
    spanOp: AnalyticsSpanOp.APP_LOADING,
    spanDescription: 'Load the app',
  });
  startV1();
} else {
  start(startV1);
}
