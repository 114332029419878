export function updateUiHandler({ domService }, { busyFlags, error }) {
    var _a;
    if (!busyFlags || Object.keys(busyFlags).length > 0) {
        domService.addUiFlag('busy');
    }
    else {
        domService.removeUiFlag('busy');
    }
    const stepElement = domService.getStepElement();
    if (!stepElement)
        throw new Error('No step element');
    const errorDiv = (_a = domService.getStepElement()) === null || _a === void 0 ? void 0 : _a.querySelector('.globalErrorMessage');
    if (error) {
        if (errorDiv) {
            errorDiv.innerHTML = error;
        }
        else {
            const newDiv = document.createElement('div');
            newDiv.innerHTML = error;
            stepElement.appendChild(newDiv);
        }
    }
    else if (errorDiv) {
        stepElement.removeChild(errorDiv);
    }
}
