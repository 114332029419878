import { Deferred } from '../helpers/deferred';
/**
 * Regsiter of async services
 *
 * Use to synchronize services i.e. assets (css) should be loaded before
 * displaying html
 */
export const asyncRegister = () => ({
    assetsLoaded: new Deferred(),
});
