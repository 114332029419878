import { logger } from '@upflowy/logger';
import { assign, spawn } from 'xstate';
import { runCodeWithContext } from '../services/expression-evaluator';
const processTransactionWithoutConditions = (transitions) => {
    let transitionWithoutCondition;
    transitions.forEach((transition, index) => {
        if (!transition.cond) {
            // We hope this will be triggered only once
            transitionWithoutCondition = index;
        }
    });
    return transitionWithoutCondition;
};
export const createConditionalCallback = (context, transitions) => (callback) => {
    const indexOfTransitionWithoutCondition = processTransactionWithoutConditions(transitions);
    const transitionsWithConditions = transitions.filter((trans) => trans.cond);
    let stopProcessing = false;
    const evals = transitionsWithConditions.map((transition, index) => runCodeWithContext(transition.cond, context)
        .then((value) => {
        // We already called the callback function, no need to go further
        if (stopProcessing)
            return;
        // if the value is truthy, we prevent other Promises from calling
        // callback function
        if (value) {
            stopProcessing = true;
            callback({ type: 'done.condition.eval', index });
        }
    })
        .catch((error) => {
        // Log the error in all cases
        logger.logException(error, {
            message: 'While evaluating transitions',
            transition: transitions[index],
        });
        // If we did not already call callback, call it with an error event
        if (!stopProcessing) {
            stopProcessing = true;
            callback({ type: 'FAILURE', errorType: 'conditionalEval', error });
        }
    }));
    Promise.allSettled(evals).then(() => {
        if (stopProcessing)
            return;
        if (indexOfTransitionWithoutCondition !== undefined) {
            callback({
                type: 'done.condition.eval',
                index: indexOfTransitionWithoutCondition,
            });
        }
        else {
            callback({
                type: 'FAILURE',
                errorType: 'conditionEval',
                message: 'No transitions evaluate to true',
            });
        }
    });
};
/**
 * Prepare for conditional evaluation
 *
 * Handle conditional logic asynchronously. Evaluate conditionals asynchronously.
 * <i>done.confition.eval</i> event is called with the resolved conditional index.
 */
export const prepareConditionalAction = assign((context, _, { action }) => ({
    conditionalRef: spawn(createConditionalCallback(context, action.transitions)),
}));
