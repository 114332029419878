import mustache from '@upflowy/mustache-async.js'; // https://github.com/janl/mustache.js
import { stripNamespace } from '../helpers/utils';
export function unflattenKeys(data) {
    const result = {};
    Object.entries(data).forEach(([key, _]) => {
        const paths = key.split('.');
        let currentNode = result;
        paths.forEach((pathPart, index) => {
            if (paths.length - 1 === index) {
                // We are on a leaf
                currentNode[pathPart] = data[key];
            }
            else if (!currentNode[pathPart]) {
                // We are on a node, and the next path does not exist yet
                currentNode[pathPart] = {};
                currentNode = currentNode[pathPart];
            }
            else {
                // We are on a node, and the next path does exist yet, no need to recreate it
                currentNode = currentNode[pathPart];
            }
        });
    });
    return result;
}
export const renderTemplate = async (html, context, escapeHtml = true) => {
    var _a, _b, _c;
    const strippedUserForm = stripNamespace({ ...(_a = context.user) === null || _a === void 0 ? void 0 : _a.form });
    const newContext = {
        meta: {
            ...unflattenKeys({ ...(_b = context.user) === null || _b === void 0 ? void 0 : _b.meta }),
        },
        ...unflattenKeys({ ...(_c = context.user) === null || _c === void 0 ? void 0 : _c.meta, ...strippedUserForm }),
    };
    // When rendering, the 'escape' function is called on all values. By default
    // this function is using the following dictionary to replace each characters
    // {
    //   '&': '&amp;',
    //   '<': '&lt;',
    //   '>': '&gt;',
    //   '"': '&quot;',
    //   "'": '&#39;',
    //   '/': '&#x2F;',
    //   '`': '&#x60;',
    //   '=': '&#x3D;'
    // }
    // source: https://github.com/janl/mustache.js/blob/813e273a658677852ab37e6f47c98a9d9352ccde/mustache.js#L71
    const renderOptions = {
        escape: (value) => value,
    };
    return mustache.render(html, newContext, undefined, escapeHtml ? undefined : renderOptions);
};
