// Events are always uppercase
export const EVENT_TYPE_STATE_ENTER = 'STATE_ENTER';
export const EVENT_TYPE_SUBMIT = 'SUBMIT';
export const EVENT_TYPE_BACK = 'BACK';
export const EVENT_TYPE_SSO_INIT = 'SSO_INIT';
export const EVENT_TYPE_REDIRECT = 'REDIRECT';
export const EVENT_TYPE_SSO_SUCCESS = 'SSO_SUCCESS';
export const EVENT_TYPE_FLOW_READY = 'FLOW_READY';
export const EVENT_TYPE_FLOW_TRIGGER = 'FLOW_TRIGGER';
export const EVENT_TYPE_FLOW_INSERT = 'FLOW_INSERT';
export const EVENT_TYPE_FLOW_COMPLETE = 'FLOW_COMPLETE';
export const EVENT_TYPE_DISMISS = 'DISMISS';
export const ConsumerEventTypes = [
    'FAILURE',
    'SUBMIT',
    'DISMISS',
    'BACK',
    'SSO_INIT',
    'SSO_SUCCESS',
    'REDIRECT',
    'FLOW_READY',
    'FLOW_TRIGGER',
    'FLOW_INSERT',
    'FLOW_COMPLETE',
    'STATE_ENTER',
    'CLOSE',
];
export const isConsumerEventType = (event) => ConsumerEventTypes.includes(event);
