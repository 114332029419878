import { evalAsync, parse } from 'expression-eval';
import { booleanExpressions } from './boolean-expressions';
import { prefixRecord } from '../../helpers/utils';
export const formatContext = (context) => {
    var _a, _b, _c;
    return ({
        abtests: context.abtests,
        ...context.user,
        form: {
            // The following line is preventing regressions with conditions like
            // form['meta.device.type']==='desktop'
            ...prefixRecord((_b = (_a = context.user) === null || _a === void 0 ? void 0 : _a.meta) !== null && _b !== void 0 ? _b : {}, 'meta.'),
            ...(_c = context.user) === null || _c === void 0 ? void 0 : _c.form,
        },
    });
};
export async function runCodeWithContext(expression, context) {
    const ast = parse(expression);
    return evalAsync(ast, {
        ...formatContext(context),
        ...booleanExpressions,
    });
}
