// replica of consumer v1
// To properly access data fields from external data, we want to make sure
// all keys are prefixed by the keyword 'data'
export const dataParseFlattenedJSON = (jsonString) => {
    const parsedJSON = JSON.parse(jsonString);
    const dataAdjustedJSON = {};
    Object.keys(parsedJSON).forEach((key) => {
        if (key !== 'data' && !key.startsWith('data.')) {
            dataAdjustedJSON[`data.${key}`] = parsedJSON[key];
        }
        else {
            dataAdjustedJSON[key] = parsedJSON[key];
        }
    });
    return dataAdjustedJSON;
};
