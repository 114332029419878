import dbConfig, { appBaseURLs } from '@upflowy/firebaseconfig';

const config = {
  env: dbConfig.env,
  sentryDNS:
    process.env.SENTRY_CONSUMER_DNS ??
    'https://1d3f0169261c4b99a55af6804ae5da76@o468157.ingest.sentry.io/5495659',
  dbConfig,
  appBaseURLs,
};

export default config;
