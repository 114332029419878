var _a, _b;
// Consumer Domain will be different for every environment
// For eg: QA => qa.upflowy.co, live => upflowy.co
// Special case for __ENV config
// eslint-disable-next-line no-underscore-dangle
const envVariables = window.__ENV || {};
export const envConfig = {
    env: (_a = envVariables.REACT_APP_ENV) !== null && _a !== void 0 ? _a : 'local',
    release: envVariables.REACT_APP_VERSION,
    sentryDNS: (_b = envVariables.SENTRY_CONSUMER_DNS) !== null && _b !== void 0 ? _b : 'https://1d3f0169261c4b99a55af6804ae5da76@o468157.ingest.sentry.io/5495659',
    functionsUrl: envVariables.REACT_APP_FUNCTIONS_URL,
    storageUrl: envVariables.REACT_APP_STORAGE_URL,
    consumerUrl: envVariables.REACT_APP_UPFLOWY_CONSUMER_URL,
    consumerDomain: envVariables.REACT_APP_UPFLOWY_CONSUMER_DOMAIN,
    consoleLog: !!process.env.REACT_APP_SENTRY_CONSOLE_LOG,
};
