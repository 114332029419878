import { getHttpsCallable } from './firebase-functions-service';
/**
 * Calls to functions api
 */
export class ApiService {
    constructor() {
        this.getFlowConfig = getHttpsCallable('getFlowConfig');
    }
    // eslint-disable-next-line class-methods-use-this
    async getLocationInfo() {
        return (await getHttpsCallable('getLocation'))().then((result) => result.data);
    }
}
