import { extraConfig as config } from '@upflowy/firebaseconfig';
import { initFirebaseApp } from './firebase-app';
async function setup() {
    var _a, _b;
    const app = await initFirebaseApp();
    if (config.emulation.functions) {
        // eslint-disable-next-line no-console
        console.log('***** Using Functions Emulator *****');
        const { connectFunctionsEmulator, getFunctions } = await import('firebase/functions');
        connectFunctionsEmulator(getFunctions(app), (_a = config.emulation.functionsHost) !== null && _a !== void 0 ? _a : 'localhost', (_b = config.emulation.functionsPort) !== null && _b !== void 0 ? _b : 5001);
    }
}
// const setupPromise = setup();
export async function getHttpsCallable(name) {
    await setup();
    const { httpsCallable, getFunctions } = await import('firebase/functions');
    return httpsCallable(getFunctions(), name);
}
