import { CONSUMER_APP_SOURCE, ORIGIN_STATE_ID, } from '@upflowy/types';
import { EVENT_TYPE_FLOW_READY } from '@upflowy/types/lib/consumer/event-types';
import { actions, assign, spawn } from 'xstate';
import { sendEventAction } from './send-event-actions';
import { cssTransitionService } from '../services/css-transition-service';
export function readyAction({ flowId, containerId, domService, resourceLoader, legacyService, asyncRegister, sendParent, sendEvent, }) {
    return actions.pure((context, _event) => [
        {
            type: 'ready.send',
            exec: sendEventAction({
                type: EVENT_TYPE_FLOW_READY,
                sendEvent,
            }),
        },
        {
            type: 'ready.sendParent',
            exec: () => {
                var _a;
                sendParent({
                    state: (_a = context.stepId) !== null && _a !== void 0 ? _a : ORIGIN_STATE_ID,
                    type: EVENT_TYPE_FLOW_READY,
                    flowId,
                    data: {
                        source: CONSUMER_APP_SOURCE,
                        containerId,
                    },
                });
            },
        },
        assign({
            stepRendererRef: (_ctx) => spawn(cssTransitionService({ domService, resourceLoader, legacyService, asyncRegister })),
        }),
    ]);
}
