import { actions, assign, createMachine, send, sendParent, } from 'xstate';
/**
 * Transition handler
 *
 * Handle transitions via a state machine.
 * This implementation might be an overkill for what is required but xstate
 * is already available. It would also pave the way for future more advanced
 * and configurable step transitions.
 *
 * @param prepare Prepare transition. i.e. load resources etc.
 * @param onEntering
 * @param onExiting
 * @param onEntered
 * @param onStarting
 * @param onExit
 * @param delays
 * @param onStarted
 */
export function createTransitionStateConfig({ onEntering, onExiting, onEntered, onStarting, onExit, prepare, delays, onStarted, }) {
    const startExitConfig = ({ onStartExit, next, delay, }) => ({
        entry: [onStartExit, send('delayComplete', { delay })].filter((x) => x),
        ...(prepare && {
            invoke: {
                src: prepare,
                onDone: {
                    actions: [
                        assign((ctx, event) => ({ ...ctx, ...event.data })),
                        send('renderComplete'),
                    ],
                },
                onError: 'error',
            },
        }),
        initial: 'waiting',
        states: {
            waiting: {
                on: {
                    renderComplete: {
                        target: 'waitingOnDelay',
                        actions: actions.log('renderComplete'),
                    },
                    delayComplete: 'processing',
                },
            },
            waitingOnDelay: {
                entry: actions.log('waitingondelay'),
                on: {
                    delayComplete: {
                        actions: actions.log(`delay complete next ${next}`),
                        target: next,
                    },
                },
            },
            processing: {
                on: {
                    renderComplete: {
                        actions: actions.log(`render complete next ${next}`),
                        target: next,
                    },
                },
            },
        },
    });
    return createMachine({
        // tsTypes: {} as import('./index.typegen').Typegen0,
        id: 'transition',
        initial: 'initial',
        context: {},
        on: {
            transition: { actions: assign((_ctx, event) => event.data), target: 'exiting' },
        },
        states: {
            initial: {
                on: {
                    transition: { actions: assign((_ctx, event) => event.data), target: 'starting' },
                },
            },
            starting: startExitConfig({
                onStartExit: onStarting,
                next: '#transition.started',
                delay: 'startingDelay',
            }),
            started: {
                entry: onStarted,
                always: 'entering',
            },
            exiting: startExitConfig({
                onStartExit: onExiting,
                next: '#transition.exited',
                delay: 'exitDelay',
            }),
            exited: {
                entry: onExit,
                always: 'entering',
            },
            entering: {
                entry: onEntering,
                after: { entryDelay: 'entered' },
            },
            entered: {
                entry: onEntered,
            },
            preparing: {
                on: {
                    prepared: 'entering',
                },
            },
            error: {
                entry: (_ctx, event) => sendParent(event),
            },
        },
    }, {
        delays,
    });
}
