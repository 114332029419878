import { ORIGIN_STATE_ID } from '@upflowy/types';
import { EVENT_TYPE_BACK, EVENT_TYPE_FLOW_TRIGGER, EVENT_TYPE_STATE_ENTER, } from '@upflowy/types/lib/consumer/event-types';
import { v4 as uuidv4 } from 'uuid';
import { actions, assign } from 'xstate';
import { sendEventAction } from './send-event-actions';
export function notifyAction({ flowId, isStandaloneApp, sendEvent, sendParent, }) {
    const events = (_context, event) => {
        var _a;
        if ((event === null || event === void 0 ? void 0 : event.type) === EVENT_TYPE_BACK) {
            return [
                {
                    type: 'back.send',
                    exec: sendEventAction({
                        type: EVENT_TYPE_BACK,
                        sendEvent,
                    }),
                },
            ];
        }
        if (event.type === EVENT_TYPE_FLOW_TRIGGER) {
            return [
                assign((ctx, _) => ({
                    ...(typeof ctx === 'object' && ctx),
                    triggerId: event.data.triggerId,
                    ...(!isStandaloneApp && { sessionId: uuidv4() }),
                })),
                {
                    type: 'flowTrigger.send',
                    exec: sendEventAction({
                        type: EVENT_TYPE_FLOW_TRIGGER,
                        triggerId: (_a = event === null || event === void 0 ? void 0 : event.data) === null || _a === void 0 ? void 0 : _a.triggerId,
                        sendEvent,
                    }),
                },
            ];
        }
        return [];
    };
    return actions.pure((context, event) => [
        ...events(context, event),
        {
            type: 'statenter.send',
            exec: sendEventAction({
                type: EVENT_TYPE_STATE_ENTER,
                sendEvent,
            }),
        },
        {
            type: 'statenter.sendParent',
            exec: () => {
                var _a;
                sendParent({
                    state: (_a = context.stepId) !== null && _a !== void 0 ? _a : ORIGIN_STATE_ID,
                    type: EVENT_TYPE_STATE_ENTER,
                    flowId,
                });
            },
        },
    ]);
}
