/**
 * Matches any characters following "/flow/" that
 * are not the "\" character.
 *
 * Fun fact: We use the "\" character as it will be escaped
 * by the browser or URL class and therefore acts the same as [^].
 *
 * @example "/other/my-cool-thing/" => ""
 * @example "/flow/my-cool-thing/" => "my-cool-thing"
 * @example "/flow/my-cool\-thing/" => "my-cool-thing"
 */
const FLOW_REGEX = /flow\/([^\\]+)/;
/**
 * Matches any characters following "/preview/" that
 * are not the "\" character that is then followed by
 * a "/" character. It then performs another match on
 * the pattern following the "/".
 *
 * Fun fact: We use the "\" character as it will be escaped
 * by the browser or URL class and therefore acts the same as [^].
 *
 * @example "/preview/my-flow-id/my-version-id" => ["my-flow-id", "my-version-id"]
 * @example "/preview/my-flow-id/my-version\-id" => ["my-flow-id", "my-version-id"]
 * @example "/preview/my-flow\-id/my-version-id" => ["my-flow-id", "my-version-id"]
 */
const PREVIEW_REGEX = /preview\/([^\\]+)\/([^\\]+)/;
export function getSubdomain(hostname) {
    const regexParse = new RegExp('[a-z-0-9]{2,63}.[a-z.]{2,5}$');
    const urlParts = regexParse.exec(hostname);
    if (urlParts && (urlParts === null || urlParts === void 0 ? void 0 : urlParts.length) > 1)
        return hostname.replace(urlParts[0], '').slice(0, -1);
    return undefined;
}
export function parseUrl(url) {
    let flowId;
    let flowVersionId;
    let customUrl;
    let isPreview = false;
    const subdomain = getSubdomain(url.hostname);
    const flowMatch = FLOW_REGEX.exec(url.pathname);
    if (flowMatch) {
        [, flowId] = flowMatch;
    }
    else {
        const previewMatch = PREVIEW_REGEX.exec(url.pathname);
        if (previewMatch) {
            [, flowId, flowVersionId] = previewMatch;
            isPreview = true;
        }
    }
    if (!flowId && url.pathname.length > 1) {
        customUrl = encodeURIComponent(url.pathname.substring(1));
    }
    return {
        flowId,
        flowVersionId,
        customUrl,
        isPreview,
        subdomain,
    };
}
