import { ORIGIN_STATE_ID } from '@upflowy/types';
import { v4 as uuidv4 } from 'uuid';
import { getMetaData } from './get-meta-data';
import { parseUrl } from './parse-url';
import { envConfig } from '../../env-config';
import { inIframe } from '../../helpers/utils';
/**
 * Provided a set of a URL Search Params, retrieve any keys and their
 * associated values the begin with `u-` which is used for prepopulating
 * context/form data. Then strip the `u-` segment from the key and return
 * an object containing the new keys and values.
 *
 * @example "?u-input-1=value1&utm_trigger=button" => { initial: '...', 'input-1': 'value-1' }
 * @example "?utm_trigger=button" => { initial: '...' }
 */
export const getUpflowyURLParams = (params) => {
    const upflowyParams = {};
    Array.from(params.keys())
        .filter((key) => key.startsWith('u-'))
        .forEach((key) => {
        // We remove the `u-` from the key for context/form population
        const strippedKey = key.substring(2);
        const value = params.get(key);
        if (value !== null) {
            upflowyParams[strippedKey] = value;
        }
    });
    return {
        initial: ORIGIN_STATE_ID,
        ...upflowyParams,
    };
};
/**
 * Provided a set of a URL Search Params, retrieve any keys and their
 * associated values the begin with `utm_` which is used for prepopulating
 * context/meta data. Then normalize the keys by lowercasing them so they're
 * consistent despite different UTM casing formats.
 *
 * @example "?u-input-1=value1&utm_trigger=button" => { 'utm_trigger': 'value-1' }
 * @example "?UTM_source=google.com&utm_Referrer=Facebook" => { 'utm_source': 'google.com', 'utm_referrer': 'Facebook' }
 */
export const getUtmParams = (params) => {
    const utmParams = {};
    Array.from(params.keys())
        .filter((key) => key.toLowerCase().startsWith('utm_'))
        .forEach((key) => {
        const normalizedKey = key.toLowerCase();
        const value = params.get(key);
        if (value !== null) {
            utmParams[normalizedKey] = value;
        }
    });
    return utmParams;
};
const getInitialContext = ({ urlParams, utmParams, location, flowId, flowVersionId, sessionId, stepId, }) => {
    const utmMeta = Object.entries(utmParams).reduce((p, [key, value]) => ({
        ...p,
        [`user.data.${key}`]: value,
        [`utm.${key}`]: value,
    }), {});
    return {
        user: {
            form: {
                ...Object.entries(urlParams).reduce((p, [key, value]) => ({
                    ...p,
                    [key === 'initial' ? 'initial' : `user.${key}`]: value,
                }), {}),
            },
            meta: {
                ...getMetaData({ location }),
                ...utmMeta,
            },
        },
        flowId,
        flowVersionId,
        busyFlags: {},
        sessionId,
        stepId,
    };
};
export const getNanoId = (customURL) => customURL.split('-')[0];
export async function createConfig({ apiService }, url) {
    var _a, _b, _c;
    const { subdomain, flowId, flowVersionId, customUrl, isPreview } = parseUrl(url);
    const modeParam = url.searchParams.get('mode');
    const iframeMode = inIframe() ? 'embedded' : 'standalone';
    // TODO: The following line should be of type AppMode, meaning that there is a
    // mismatch between consumer app v1 and consumer app v2
    const mode = modeParam && ['embedded', 'standalone', 'preview'].includes(modeParam) ? modeParam : iframeMode;
    const urlParams = getUpflowyURLParams(url.searchParams);
    const utmParams = getUtmParams(url.searchParams);
    const location = await apiService.getLocationInfo();
    const implementation = ((_a = url.searchParams.get('implementation')) !== null && _a !== void 0 ? _a : 'live');
    let configResourceUrl;
    let baseResourceUrl;
    const fallbackResourceUrl = `${envConfig.functionsUrl}/consumerHandler`;
    if (isPreview || implementation === 'preview') {
        configResourceUrl = fallbackResourceUrl;
        baseResourceUrl = configResourceUrl;
    }
    else {
        baseResourceUrl = `${envConfig.storageUrl}published`;
        if (customUrl) {
            const nanoId = getNanoId(customUrl);
            configResourceUrl = `${envConfig.storageUrl}published%2Furls%2F${nanoId}`;
        }
        else {
            configResourceUrl = `${envConfig.storageUrl}published`;
        }
    }
    const sessionId = uuidv4();
    const searchParams = Object.fromEntries(Array(...url.searchParams.entries()));
    const origin = (_c = (_b = searchParams.origin) !== null && _b !== void 0 ? _b : url.searchParams.get('origin')) !== null && _c !== void 0 ? _c : window.location.origin;
    return {
        containerId: url.searchParams.get('containerId'),
        origin,
        mode,
        implementation,
        urlParams,
        utmParams: getUtmParams(url.searchParams),
        initialState: urlParams.initial,
        initialContext: getInitialContext({
            urlParams,
            utmParams,
            location,
            flowId,
            flowVersionId,
            stepId: ORIGIN_STATE_ID,
            sessionId,
        }),
        baseResourceUrl,
        configResourceUrl,
        fallbackResourceUrl,
        isStandaloneApp: mode === 'standalone' || mode === 'preview',
        flowId,
        flowVersionId,
        consumerAppSource: 'upflowy-consumer-app',
        customUrl,
        subdomain,
        debug: urlParams.debug !== undefined,
    };
}
