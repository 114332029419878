import { ConsumerAnalyticsService, FlowStatus, logger } from '@upflowy/logger';
import { createTransitionStateConfig } from './create-transition-state-config';
import { renderTemplate } from '../mustache-renderer';
import './transitions.css';
/**
 * Force repaint
 *
 * This is to force a dom repaint, which is necessary in order to transition
 * styles when adding a class name.
 *
 */
const forceRepaint = (element) => {
    if (element)
        element.scrollTop;
};
export function cssTransitionService({ resourceLoader, legacyService, asyncRegister, domService, startingDelay = 0, entryDelay = 400, exitDelay = 300, }) {
    var _a;
    const classList = (_a = domService.getUiSettingsElement()) === null || _a === void 0 ? void 0 : _a.classList;
    const stepElement = domService.getStepElement();
    if (!classList)
        throw new Error('Animate class element not found');
    const baseClass = 'step-block';
    return createTransitionStateConfig({
        prepare: async ({ stepId, parentContext }) => {
            const loaderPromise = resourceLoader.load(`/steps/${stepId}/index.html`);
            await asyncRegister.assetsLoaded.promise;
            const rawHtml = await loaderPromise;
            const html = rawHtml ? await renderTemplate(rawHtml, parentContext) : '';
            return {
                html,
            };
        },
        onStarting: () => {
            classList.add(`${baseClass}-enter`);
        },
        onEntering: ({ stepId, hasNavigateBack, html }) => {
            domService.setStepActive(stepId);
            if (hasNavigateBack) {
                domService.addUiFlag('has-navigate-back');
            }
            else {
                domService.removeUiFlag('has-navigate-back');
            }
            if (stepElement) {
                stepElement.innerHTML = '';
                stepElement.appendChild(document.createRange().createContextualFragment(html !== null && html !== void 0 ? html : ''));
            }
            forceRepaint(domService.getUiSettingsElement());
            classList.add(`${baseClass}-enter-active`);
            classList.remove(`${baseClass}-exit-done`);
            classList.remove(`${baseClass}-enter`);
            legacyService.postRender(stepId).catch((e) => logger.logException(e, {
                message: 'Trying to render legacy service',
            }));
        },
        onEntered: () => {
            ConsumerAnalyticsService.endTransaction(FlowStatus.FOUND);
            classList.add(`${baseClass}-enter-done`);
            classList.remove(`${baseClass}-enter-active`);
            if (stepElement)
                stepElement.removeAttribute('style');
        },
        onExiting: () => {
            classList.add(`${baseClass}-exit-active`);
            classList.remove(`${baseClass}-enter-done`);
            classList.remove(`${baseClass}-enter-active`);
        },
        onExit: () => {
            classList.add(`${baseClass}-exit-done`);
            classList.remove(`${baseClass}-exit-active`);
        },
        delays: {
            startingDelay,
            entryDelay,
            exitDelay,
        },
    });
}
