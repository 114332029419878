import { Deferred } from '../helpers/deferred';
export class CustomCSSService {
    constructor(domService) {
        this.domService = domService;
        this.deferred = new Deferred();
        this.hasCustomCssPromise = this.deferred.promise;
    }
    async handle(customCSS, resourceLoader) {
        if (customCSS) {
            await this.domService.addStyle({
                url: resourceLoader.toResourceUrl('/custom.css'),
                slot: 'custom-css',
            });
        }
        this.deferred.resolve(true);
    }
}
