import { logger } from '@upflowy/logger';
import { createMachine, interpret, } from 'xstate';
import { updateUiHandler } from '../actions/update-ui-handler';
export function stateMachineService({ machineConfig, actionHandlers, context, initialState, guards, domService, debug, }) {
    // const machineConfig = await loadJsonResource<XStateMachineConfig>('state-config.json');
    if (debug)
        import('@xstate/inspect').then(({ inspect }) => inspect({ iframe: false }));
    const machine = createMachine({
        ...machineConfig,
        initial: initialState !== null && initialState !== void 0 ? initialState : machineConfig.initial,
        context,
    }, {
        // actions: actionHandlers(config),
        actions: actionHandlers,
        guards,
    });
    const interpreter = interpret(machine, { devTools: debug }).onTransition((trans) => {
        logger.setContext('state', { eventType: trans.event.type, state: trans.value });
        updateUiHandler({ domService }, trans.context);
    });
    if (debug) {
        import('@xstate/inspect').then(({ inspect }) => {
            inspect({ iframe: false });
            interpreter.start();
        });
    }
    else {
        interpreter.start();
    }
    return interpreter;
}
