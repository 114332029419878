/**
 * Check if in IFrame
 */
export function inIframe() {
    try {
        return window.self !== window.top;
    }
    catch (e) {
        return true;
    }
}
export const prefixRecord = (data, prefix) => {
    const mapped = {};
    Object.entries(data).forEach(([key, value]) => {
        mapped[`${prefix}${key}`] = value;
    });
    return mapped;
};
export const stripNamespace = (data) => {
    const stripped = {};
    Object.entries(data).forEach(([key, value]) => {
        const newKey = key.substring(key.indexOf('.') + 1, key.length);
        stripped[newKey] = value;
    });
    return stripped;
};
