/**
 * Helper function to make things a little bit more typesafe.
 *
 * XState does not pass on meta data to declarative actions.
 *
 * @param meta
 */
export function extractActionMeta(meta) {
    return meta.action;
}
