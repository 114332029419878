import { logger } from '@upflowy/logger';
import '../extra.css';
export async function renderVersionAction({ apiService, userPromise, flowId, flowVersionId, resourceLoader, asyncRegister, isStandaloneApp, watermarkService, customCSSService, domService, }) {
    try {
        if (isStandaloneApp)
            domService.addUiFlag('is-standalone');
        const versionCssPromise = domService.addStyle({
            url: resourceLoader.toResourceUrl('/index.css'),
            slot: 'version',
        });
        const assets = await resourceLoader.loadJson('/assets.json');
        if (!assets) {
            logger.warn('Assets expected to be present');
        }
        const user = await userPromise;
        const result = await (await apiService.getFlowConfig)({
            flowId,
            flowVersionId,
            idToken: await user.getIdToken(),
        });
        const promises = (assets === null || assets === void 0 ? void 0 : assets.map((asset) => {
            if (asset.type === 'css')
                return domService.addStyle({
                    url: asset.url,
                    slot: 'version-asset',
                });
            if (asset.type === 'js') {
                return domService.addScript(asset.url);
            }
            // eslint-disable-next-line sonarjs/no-collapsible-if
            if (asset.type === 'font') {
                if (asset.url.startsWith('https://fonts.googleapis.com/') ||
                    result.data.customFonts ||
                    (!result.data.customFonts && asset.url.startsWith('https://fonts.googleapis.com/'))) {
                    return domService.addFont(asset);
                }
            }
            return Promise.resolve(true);
        })) || [];
        // Ensure all css and assets are loaded
        await Promise.all([...promises, versionCssPromise]);
        await customCSSService.handle(result.data.customCSS, resourceLoader);
        asyncRegister.assetsLoaded.resolve(true);
        await watermarkService.handle(result.data.showWatermark);
    }
    catch (e) {
        logger.logException(e, { message: 'While rendering version' });
    }
}
