import { DISMISSED_STATE_ID } from '@upflowy/types/lib';
import { UrlResourceLoader } from '../url-resource-loader';
export async function loadFlowConfig({ configResourceUrl, fallbackResourceUrl, flowId, flowVersionId, }) {
    const resourceLoader = UrlResourceLoader.create({
        baseResourceUrl: configResourceUrl,
        flowId,
        flowVersionId,
    });
    try {
        const flowConfig = await resourceLoader.loadJson('/config.json');
        if (!flowConfig) {
            // Most likely legacy. Load from functions
            return await UrlResourceLoader.create({
                baseResourceUrl: fallbackResourceUrl,
                flowId,
                flowVersionId,
            }).loadJson('/config.json');
        }
        // This is to fix flows having broken dismiss state
        if (flowConfig.machine.states) {
            // eslint-disable-next-line dot-notation
            flowConfig.machine.states[DISMISSED_STATE_ID].entry = 'dismiss';
        }
        return flowConfig;
    }
    catch (error) {
        return null;
    }
}
